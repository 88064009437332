html,
body {
  margin: 0px;
}

button {
  background-color: white;
  color: black;
  border-radius: 12px;
  font-family: "Lucida Console", "Courier New", monospace;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.editor {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
  height: 100vh;
  background-color: black;
  color: white;
  font-family: "Lucida Console", "Courier New", monospace;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.form {
  margin-top: auto;
  display: flex;
}

.query-textbox {
  margin: 10px;
  flex-grow: 1;
  height: 100px;
  resize: none;
  border-radius: 5px;
  background-color: #404040;
  color: white;
  font-family: "Lucida Console", "Courier New", monospace;
}

.submit-button {
  margin: 5px;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #404040;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}
