tr:nth-child(even) {
  background-color: rgb(7, 7, 7);
}

th {
  background-color: black;
  padding-right: 20px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

.query-results {
  overflow-y: scroll;
}

.query-results-table {
  width: 100%;
}
