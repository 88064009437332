th {
  text-align: left;
  padding-left: 5px;
  padding-bottom: 10px;
}

tr:nth-child(even) {
  background-color: rgb(7, 7, 7);
}

.query-results {
  overflow-y: scroll;
}

.query-results-table {
  width: 100%;
}
